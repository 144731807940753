import { EffectCallback, useEffect } from 'react';

/**
 * Abstraction of useEffect hook.
 * This will only ever run once when the component mounts. Modern React
 * equivalent of componentDidMount() lifecycle method.
 *
 * @param callback - The side effect function you want to run
 */
const useMountEffect = (callback: EffectCallback) => useEffect(callback, []);

export default useMountEffect;

import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import SEO from '../components/seo';
import Spinner from '../components/spinner';
import useMountEffect from '../hooks/mount-effect';

/**
 * The /login route is not used or fully rendered by the app itself since Auth0
 * handles all of the user auth journey. However, it sometimes needs to redirect
 * users to a page route on the app which in turn redirects to their /authorize
 * endpoint.
 *
 * TODO: Maybe remove this page if not needed. It just provides a /login path that we don't currently use.
 */
const LoginPage = () => {
  const { isLoading, loginWithRedirect } = useAuth0();

  useMountEffect(() => {
    loginWithRedirect();
  });

  return (
    <>
      <SEO title="Log in" />
      <Spinner isLoading={isLoading} />
    </>
  );
};

export default LoginPage;
